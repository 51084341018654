<template>
  <div class="scene-container">
    <template v-for="(item, index) in sceneSource" :key="item.id">
      <div class="scene-item">
        <div class="left">
          <div class="img-box" v-if="index % 2 === 0">
            <img :src="`/api/website/${item.picture}`" alt="">
          </div>
          <div class="content-box contet-left" v-else>
            <div class="title">
              <svg-icon icon-class="ic_title"/>
              <span>{{item.sceneName}}</span>
            </div>
            <div class="desc">
              {{item.content}}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="img-box" v-if="index % 2 !== 0">
            <img :src="`/api/website/${item.picture}`" alt="">
          </div>
          <div class="content-box content-right" v-else>
            <div class="title">
              <svg-icon icon-class="ic_title"/>
              <span>{{item.sceneName}}</span>
            </div>
            <div class="desc">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    sceneSource: {
      type: Array,
      required: true
    }
  }
})
</script>
<style lang="scss" scoped>
  .scene-container {
    .scene-item {
      width: 100%;
      height: 340px;
      display: flex;
      margin-top: 40px;
      .left {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .img-box {
        width: 100%;
        height: 100%;
      }
      .content-box {
        height: 240px;
        background: #FFFFFF;
        border: 1px solid #E9EEF0;
        box-shadow: -4px 5px 12px 2px rgba(27,96,132,0.10);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 40px;
        .title {
          display: flex;
          align-items: center;
          svg {
            width: 20px;
            height: 20px;
          }
          span {
            font-size: 28px;
            color: #474747;
            font-weight: 600;
            margin-left: 16px;
          }
        }
        .desc {
          margin-top: 25px;
          font-size: 16px;
          color: #474747;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contet-left {
      margin-right: -74px;
      width: calc(100% + 74px);
      position: relative;
    }
    .content-right {
      margin-left: -74px;
      width: calc(100% + 74px);
    }
  }
</style>
