<template>
  <div class="detail-container">
    <div class="banner-cj">
      <div class="banner">
        <div class="center">
          <head-navigation/>
          <div class="info">
            <h1>{{detailInfo.algorithmName}}</h1>
            <p>
              {{detailInfo.content}}
            </p>
          </div>
          <div class="search-box">
            <!-- <div class="hot-search">
              <h5>热门搜索：</h5>
              <ul>
                <li>烟雾识别</li>
                <li>抽烟识别</li>
                <li>无人机光伏巡检</li>
              </ul>
            </div> -->
            <div class="search-form">
              <div class="input-box">
                <input
                  type="text"
                  placeholder="搜索算法"
                  v-model="algorithmName"
                  @keyup.enter="handleSearchAlgorithm"
                />
                <svg-icon
                  icon-class="ic_search"
                  @click="handleSearchAlgorithm"
                />
              </div>
              <button class="button-default" @click="() => open = true">提交定制需求</button>
            </div>
          </div>
          <div
            class="img-box"
            :style="{ background: `url(/api/website/${detailInfo.picture}) no-repeat center`}"
          >
            <span>{{detailInfo.algorithmName}}</span>
          </div>
        </div>
      </div>
      <div class="cj">
        <div class="center">
          <ul>
            <template v-for="item in detailInfo.industryNameList" :key="item">
              <li>
                <span>{{item}}</span>
              </li>
            </template>
            <!-- <li>
              <svg-icon icon-class="ic_zhihuichengguan"/>
              <span>智慧城管</span>
            </li>
            <li>
              <svg-icon icon-class="ic_industry"/>
              <span>智慧工业</span>
            </li>
            <li>
              <svg-icon icon-class="ic_kitchen"/>
              <span>明厨亮灶</span>
            </li>
            <li>
              <svg-icon icon-class="ic_gas_station"/>
              <span>智慧油站</span>
            </li>
            <li>
              <svg-icon icon-class="ic_mountion"/>
              <span>智慧矿山</span>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="scene-content">
      <h1>应用场景</h1>
      <scene-card
        :scene-source="sceneSource"
      />
    </div>
    <div class="recommend-content">
      <h1>相关算法推荐</h1>
      <application-scenario
        :algorithm-data="algorithmData"
        @link="handleLink"
      />
    </div>
    <Footer/>
    <ApplyTry v-model:open="open"/>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import HeadNavigation from '@/components/HeadNavigation'
import Footer from '@/components/footer/Footer'
import ApplicationScenario from './component/ApplicationScenario'
import SceneCard from './component/SceneCard'
import { useRoute, useRouter } from 'vue-router'
import http from '@/utils/http'
import ApplyTry from '@/components/apply-try/ApplyTry.vue'

export default defineComponent({
  components: {
    HeadNavigation,
    Footer,
    ApplicationScenario,
    SceneCard,
    ApplyTry
  },
  setup () {
    const open = ref(false)
    const route = useRoute()
    const router = useRouter()

    const detailInfo = reactive({
      algorithmName: '',
      content: '',
      industryNameList: [],
      picture: ''
    })

    const queryDetail = () => {
      http.request({
        method: 'get',
        url: `/website/algorithm/home/queryAlgorithmById/${route.query.id}`
      }).then(res => {
        Object.keys(detailInfo).forEach(key => {
          detailInfo[key] = res.data[key]
        })
      })
    }

    const sceneSource = ref([])
    const querySceneInfo = () => {
      http.request({
        method: 'get',
        url: `/website/scene/home/querySceneByAlgorithmId/${route.query.id}`
      }).then(res => {
        sceneSource.value = res.data
      })
    }

    const algorithmData = ref([])
    const queryAlgorithmRecommend = () => {
      http.request({
        method: 'get',
        url: `/website/algorithm/home/queryAlgorithmRecommend/${route.query.id}`
      }).then(res => {
        algorithmData.value = res.data
      })
    }

    const algorithmName = ref('')
    const handleSearchAlgorithm = () => {
      router.push({
        path: '/algorithm-mall',
        query: {
          name: algorithmName.value
        }
      })
    }

    const handleLink = (id) => {
      router.push({
        path: '/algorithm-mall-detail',
        query: {
          id: id
        }
      })
      document.documentElement.scrollTop = 0
    }

    watch(() => route.query.id, () => {
      queryDetail()
    })

    onMounted(() => {
      queryDetail()
      querySceneInfo()
      queryAlgorithmRecommend()
    })

    return {
      algorithmData,
      sceneSource,
      detailInfo,
      open,
      algorithmName,
      handleSearchAlgorithm,
      handleLink
    }
  }
})
</script>
<style lang="scss" scoped>
  .banner-cj {
    .banner {
      height: 458px;
      background: #3273F9;
      border: 1px solid #979797;
      box-sizing: border-box;
      .center {
        position: relative;
        .navigation {
          position: static;
          :deep(.center) {
            width: 100%;
          }
        }
        .info {
          margin-top: 53px;
          h1 {
            display: inline-block;
            height: 60px;
            line-height: 60px;
            font-size: 30px;
            font-weight: 700;
            padding-left: 16px;
            color: #ffffff;
            background-image: linear-gradient(-86deg, rgba(50,115,249,0.00) 0%, rgba(255,255,255,0.45) 100%);
          }
          p {
            width: 940px;
            color: #ffffff;
            font-size: 16px;
            margin-top: 18px;
          }
        }
        .search-box {
          margin-top: 62px;
          .hot-search {
            font-size: 14px;
            color: #ffffff;
            display: flex;
            align-items: center;
            h5 {
              font-weight: 700;
              font-size: 14px;
            }
            ul {
              list-style: none;
              padding: 0;
              margin: 0;
              display: flex;
              li {
                display: flex;
                align-items: center;
                &:not(:first-child) {
                  &::before {
                    display: inline-block;
                    content: '';
                    height: 14px;
                    width: 1px;
                    background-color: rgba(255,255,255,0.60);
                    margin: 0 5px;
                  }
                }
              }
            }
          }
          .search-form {
            display: flex;
            align-items: center;
            margin-top: 11px;
            .input-box {
              width: 474px;
              height: 40px;
              background-color: rgba(255,255,255,0.16);
              border: 2px solid #FFFFFF;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 0 23px;
              input {
                height: 32px;
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 16px;
                color: #ffffff;
                flex: 1;
                &::placeholder {
                  color: #ffffff;
                }
              }
              svg {
                width: 16px;
                height: 16px;
                cursor: pointer;
              }
            }
            button {
              margin-left: 14px;
            }
          }
        }
        .img-box {
          position: absolute;
          right: 0px;
          top: 117px;
          width: 285px;
          height: 405px;
          // background: url(../../assets/img/img_fire.png) no-repeat center;
          background-size: cover;
          box-sizing: border-box;
          padding-top: 22px;
          text-align: right;
          span {
            background-color: #3273F9;
            color: #ffffff;
            font-size: 14px;
            padding: 10px 16px;
          }
        }
      }
    }
    .cj {
      height: 64px;
      background-color: #ffffff;
      box-shadow: 0 2px 27px 0 rgba(144,161,230,0.50);
      .center {
        ul {
          height: 100%;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          li {
            display: flex;
            align-items: center;
            svg {
              width: 40px;
              height: 40px;
            }
            span {
              font-size: 14px;
              color: #474747;
              font-weight: 700;
            }
            &:not(:first-child) {
              margin-left: 60px;
            }
          }
        }
      }
    }
    :where(.banner, .cj) {
      .center {
        height: 100%;
      }
    }
  }
  .scene-content {
    margin: 80px auto 0 auto;
    h1 {
      font-size: 16px;
      color: #474747;
      font-weight: 700;
    }
    .scene-container {
      margin-top: 19px;
    }
  }
  .recommend-content {
    margin: 80px auto 0 auto;
    h1 {
      font-size: 16px;
      color: #474747;
      font-weight: 700;
    }
    .application-container {
      width: 100%;
    }
  }
  .footer {
    margin-top: 100px;
  }
  @media screen and (min-width: 1366px){
    .banner-cj {
      .banner {
        .center {
          width: 91%;
          margin: 0 auto;
        }
      }
      .cj {
        .center {
          width: 91%;
          margin: 0 auto;
        }
      }
    }
    .recommend-content {
      width: 91%;
    }
    .scene-content {
      width: 91%;
    }
  }
  @media screen and (min-width: 1920px){
    .banner-cj {
      .banner {
        .center {
          width: 73%;
          margin: 0 auto;
        }
      }
      .cj {
        .center {
          width: 73%;
          margin: 0 auto;
        }
      }
    }
    .recommend-content {
      width: 73%;
    }
    .scene-content {
      width: 73%;
    }
  }
</style>
